//misc
import {
  isEmpty,
  isEqual,
  forEach,
  get,
  map,
  some,
  keys,
  values,
  uniq,
  reduce,
  filter,
  isNil,
  every,
  each,
  has
} from "lodash-es"
import { getFormattedDate } from "@/helpers/dates"
import { format as dateFormat } from "date-fns"
import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

export default {
  data() {
    return {
      saleStopDays: {}
    }
  },

  watch: {
    inventories: {
      handler() {
        if (isEmpty(this.inventories)) {
          this.saleStopDays = {}
        } else {
          this.setHeaderSaleStopDays()
        }
      }
    }
  },

  computed: {
    calendarHeaderGroupedDateRange() {
      return reduce(
        this.dateRange,
        (object, date) => {
          const month = dateFormat(date, "yyyy-MM")
          object[month] ||= {}
          object[month][getFormattedDate(date)] = date
          return object
        },
        {}
      )
    },

    activeOtaIdsByCarClassId({ inventories }) {
      return reduce(
        inventories,
        (object, { ota_data }, key) => {
          object[key] = this.collectActiveOtaIds(ota_data)
          return object
        },
        {}
      )
    }
  },

  methods: {
    changeSaleStopDay(day, value) {
      const changedInventories = reduce(
        this.selectedCarClasses,
        (object, selectedCarClass) => {
          if (!isEmpty(this.inventories[selectedCarClass.id])) {
            object[selectedCarClass.id] = this.buildDayChanges(selectedCarClass.id, day, value)
          }
          return object
        },
        {}
      )
      this.handleChangeInventories({ ...this.changedInventories, ...changedInventories })
    },

    changeSaleStopDayInventoryManagement(day, value) {
      each(this.selectedCarClasses, ({ id: carClassId }) => {
        if (!isEmpty(this.inventories[carClassId])) {
          const changes = this.buildDayChangesInventoryManagement(carClassId, day, value)
          this.changeInventory(changes)
        }
      })
    },

    updateHeaderSaleStopDays(changedInventories) {
      const days = this.collectChangedDays(changedInventories)
      forEach(days, day => {
        const existingDay = this.saleStopDays[day]
        const daySaleStops = this.collectChangedDaySaleStops(day)
        const value = every(daySaleStops, saleStop => saleStop)
        this.saleStopDays[day] = {
          ...existingDay,
          value,
          indeterminate: !value && some(daySaleStops, saleStop => saleStop),
          changed: !isEqual(existingDay?.initialSaleStops, daySaleStops)
        }
      })
    },

    // inner methods
    buildDayChanges(carClassId, day, value) {
      const existingChanges = this.changedInventories[carClassId] || {}
      return {
        ...existingChanges,
        [day]: {
          shared_inventory_limit: 0,
          ...this.inventories[carClassId].inventory_data[day],
          ...this.changedInventories[carClassId]?.[day],
          sale_stop: this.buildCarClassSaleStop(carClassId, value)
        }
      }
    },

    buildDayChangesInventoryManagement(carClassId, day, value) {
      const existingChanges = this.totalChangedInventories[[carClassId, getFormattedDate(day)]] || {}
      return {
        ...existingChanges,
        date: getFormattedDate(day),
        car_class_id: carClassId,
        inventory: {
          ...this.inventories[carClassId].inventory_by_days[getFormattedDate(day)],
          ...existingChanges.inventory,
          sale_stop_ota_ids: this.buildCarClassSaleStopInventoryManagement(carClassId, value)
        }
      }
    },

    buildCarClassSaleStopInventoryManagement(carClassId, value) {
      if (value) {
        return [...this.activeOtaIdsByCarClassId[carClassId]]
      }
      return []
    },

    buildCarClassSaleStop(carClassId, value) {
      return reduce(
        this.activeOtaIdsByCarClassId[carClassId],
        (object, otaId) => {
          object[otaId] = value
          return object
        },
        {}
      )
    },

    setHeaderSaleStopDays() {
      this.saleStopDays = reduce(
        this.formattedDateRange,
        (object, day) => {
          object[day] = this.buildDaySaleStop(day)
          return object
        },
        {}
      )
    },

    buildDaySaleStop(day) {
      const daySaleStops = this.collectDaySaleStops(day)
      const value = every(daySaleStops, saleStop => saleStop)
      return {
        value,
        changed: false,
        indeterminate: !value && some(daySaleStops, saleStop => saleStop),
        initialSaleStops: daySaleStops
      }
    },

    collectDaySaleStops(day) {
      return reduce(
        this.selectedCarClasses,
        (array, selectedCarClass) => {
          array.push(...this.collectCarClassSaleStops(day, selectedCarClass.id))
          return array
        },
        []
      )
    },

    collectChangedDaySaleStops(day) {
      return reduce(
        this.selectedCarClasses,
        (array, selectedCarClass) => {
          array.push(...this.collectChangedCarClassSaleStops(day, selectedCarClass.id))
          return array
        },
        []
      )
    },

    collectCarClassSaleStops(day, carClassId) {
      return reduce(
        this.activeOtaIdsByCarClassId[carClassId],
        (array, activeOtaId) => {
          const value = isOrganizationWithSharedInventory()
            ? get(this.inventories, `${carClassId}.inventory_data.${day}.sale_stop.${activeOtaId}`, false)
            : get(this.inventories, `${carClassId}.inventory_by_days.${day}.sale_stop_ota_ids`, []).includes(
                activeOtaId
              )
          array.push(value)
          return array
        },
        []
      )
    },

    collectChangedCarClassSaleStops(day, carClassId) {
      return reduce(
        this.activeOtaIdsByCarClassId[carClassId],
        (array, activeOtaId) => {
          if (isOrganizationWithSharedInventory()) {
            const saleStop = get(this.changedInventories, `${carClassId}.${day}.sale_stop.${activeOtaId}`, null)
            if (!isNil(saleStop)) {
              array.push(saleStop)
            } else {
              const value = get(this.inventories, `${carClassId}.inventory_data.${day}.sale_stop.${activeOtaId}`, false)
              array.push(value)
            }
          } else {
            let saleStop = null
            if (has(this.totalChangedInventories, `${carClassId},${day}.inventory.sale_stop_ota_ids`)) {
              saleStop = get(
                this.totalChangedInventories,
                `${carClassId},${day}.inventory.sale_stop_ota_ids`,
                []
              ).includes(activeOtaId)
            } else {
              saleStop = get(this.inventories, `${carClassId}.inventory_by_days.${day}.sale_stop_ota_ids`, []).includes(
                activeOtaId
              )
            }

            array.push(saleStop)
          }
          return array
        },
        []
      )
    },

    collectChangedDays(changedInventories) {
      return uniq(
        reduce(
          values(changedInventories),
          (array, day) => {
            if (isOrganizationWithSharedInventory()) {
              array.push(...keys(day))
            } else {
              array.push(day.date)
            }
            return array
          },
          []
        )
      )
    },

    collectActiveOtaIds(otaData) {
      return map(
        filter(otaData, ota => {
          if (isOrganizationWithSharedInventory()) {
            return !ota.matchings_wrong && !ota.inactive_ota
          }
          return (
            !ota.not_matched_car_class &&
            !ota.not_matched_shop &&
            !ota.inactive_source_shop &&
            !ota.inactive_source_car_class &&
            !ota.inactive_ota
          )
        }),
        "ota_id"
      )
    }
  }
}
